import HttpService from "./HttpService"

class NotificationsService extends HttpService {
  getNotifications(params?: any) {
    return this.get("/natural-person/notifications", params)
  }

  getSubscriptions() {
    return this.get("/natural-person/subscriptions")
  }

  readNotifications() {
    return this.get("/natural-person/notifications/read")
  }

  postSubscribeCompany(data: { company_id: number }) {
    return this.post("/natural-person/subscriptions", data)
  }

  postUnsubscribeCompany(data: { company_id: number }) {
    return this.post("api/natural-person/subscriptions/unsubscribe", data)
  }
}

export default new NotificationsService()
